import React, { forwardRef, ReactNode, useEffect, useState } from 'react'
import {
  Box,
  createPolymorphicComponent,
  createStyles,
  MantineSize,
  Select,
  SelectItem as SI,
  SelectProps,
} from '@mantine/core'
import { Typography, VStack, XelaColor } from '@/XelaReact'
import { IconX } from '@tabler/icons-react'
import { omit } from 'lodash'

interface XSelectWithRoleProps extends SelectProps {
  placeholder?: string
  label?: ReactNode
  size?: MantineSize
}

const useStyles = createStyles(
  (
    _,
    params: {
      value?: string | null
      placeholder?: string
      focused?: boolean
      label?: ReactNode
      size?: MantineSize
      icon?: ReactNode
    }
  ) => ({
    root: {
      fontFamily: 'Nunito Sans',
      position: 'relative',
      width: '100%',
    },

    input: {
      fontSize: params.size === 'sm' ? '12px' : '14px',
      fontWeight: params.size === 'sm' ? 700 : 700,
      height: params.size === 'sm' ? '45px' : '56px',
      padding: params.label
        ? params.size === 'sm'
          ? '18px 22px 2px'
          : '26px 22px 6px'
        : params.size === 'sm'
        ? '2px 22px 2px'
        : '5px 22px 6px',
      border: '1px solid #E8E8EA',
      borderRadius: params.size === 'sm' ? '16px' : '18px',
      '&:hover, &:focus': {
        padding: params.label
          ? params.size === 'sm'
            ? '17px 20.5px 1px'
            : '25px 20.5px 5px'
          : params.size === 'sm'
          ? '1px 20.5px 1px'
          : '4px 20.5px 5px',
        border: '2px solid #003CFF',
      },
      '&:focus': {
        color: '#003CFF !important',
      },
      '&::placeholder': {
        color: XelaColor.Gray10,
        fontWeight: 400,
      },
    },

    label: {
      fontSize: params.focused || params.placeholder || params.value ? '10px' : '13px',
      lineHeight: '15px',
      fontWeight: 700,
      position: 'absolute',
      pointerEvents: 'none',
      paddingLeft: '23px',
      transition: 'all 300ms ease',
      paddingTop: params.label
        ? `${
            params.focused || params.placeholder || params.value
              ? params.size === 'sm'
                ? '8px'
                : '12px'
              : params.size === 'sm'
              ? '16px'
              : '20px'
          }`
        : '0px',
      zIndex: 1,
      color: XelaColor.Gray3,
      '&:hover, &:focus': {
        paddingLeft: '25px',
      },
    },

    dropdown: {
      minWidth: '200px !important',
      fontFamily: 'Nunito Sans',
      boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.08), 0px 4px 48px rgba(0, 0, 0, 0.08)',
      borderRadius: '12px',
      padding: '8px !important',
      zIndex: 9999,
    },

    item: {
      width: '90%',
      fontSize: params.size === 'sm' ? '12px' : '14px',
      fontWeight: params.size === 'sm' ? 600 : 700,
      color: '#1D1929',
      padding: params.size === 'sm' ? '8px 12px !important' : '10px 12px !important',
      '&[data-selected]': {
        '&, &:hover': {
          color: '#FFFFFF',
          background: '#003CFF !important',
          borderRadius: '12px',
        },
      },
      '&[data-focused]': {
        '&, &:hover': {
          background: '#F9F9F9',
          borderRadius: '12px',
        },
      },
      '&:hover': {
        borderRadius: '12px',
      },
    },
  })
)

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  role: string
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(function SelectItemComponent(
  { label, role, ...others }: ItemProps,
  ref
) {
  return (
    <Box ref={ref} {...others}>
      <VStack>
        <Typography variant={'body-small-bold'}>{label}</Typography>
        {role != 'option' && (
          <Typography variant={'sub-caption-bold'} color={XelaColor.Gray8}>
            {role}
          </Typography>
        )}
      </VStack>
    </Box>
  )
})

// eslint-disable-next-line react/display-name
const _XSelectWithRole = forwardRef<
  HTMLInputElement,
  Omit<XSelectWithRoleProps, 'data'> & {
    data?: SI[] | string[]
    onInit?: (callback: (data: SI[] | string[]) => void) => void
  }
>(({ children, ...props }, ref) => {
  const [data, setData] = useState<SI[] | string[]>([])
  const [val, setVal] = useState(props.value)
  const [focused, setFocused] = useState(false)

  const { classes } = useStyles({
    label: props.label,
    placeholder: props.placeholder,
    icon: props.icon,
    value: val,
    size: props.size,
    focused,
  })

  useEffect(() => {
    setVal(props.value)
  }, [props.value])

  useEffect(() => {
    if (props.data) {
      setData(props.data)
    }
  }, [props])

  useEffect(() => {
    if (props.onInit) {
      props.onInit((data) => {
        if (data && data.length > 0) {
          // Check if data is an array of objects or an array of strings
          const isObjectArray = typeof data[0] === 'object' && data[0] !== null

          if (isObjectArray) {
            setData(data as SI[])

            if (val !== '') {
              // Check if val exists in data, otherwise set the first element's value as val
              if (!val || !(data as SI[]).find((item) => item.value === val)) {
                setVal((data as SI[])[0].value)
                props.onChange && props.onChange((data as SI[])[0].value)
              }
            }
          } else {
            // If data is an array of strings, convert it to the required format
            const convertedData: SI[] = (data as string[]).map((item) => ({
              label: item,
              value: item,
            }))

            setData(convertedData)

            if (val !== '') {
              // Check if val exists in data, otherwise set the first element as val
              if (!val || !(data as string[]).includes(val)) {
                setVal((data as string[])[0])
                props.onChange && props.onChange((data as string[])[0])
              }
            }
          }
        }
      })
    }
  }, [])

  return (
    <Box style={{ position: 'relative', width: '100%' }}>
      <Select
        searchable
        autoComplete={'off'}
        ref={ref}
        {...omit(props, ['data', 'onInit', 'clearable'])}
        data={data.sort((a, b) => {
          if (typeof a === 'object' && a !== null && typeof b === 'object' && b !== null) {
            return a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1
          } else {
            return 0
          }
        })}
        classNames={classes}
        itemComponent={SelectItem}
        maxDropdownHeight={180}
        value={val}
        onFocus={(event) => {
          setFocused(true)
          if (props.onFocus) {
            props.onFocus(event)
          }
        }}
        onBlur={(event) => {
          setFocused(false)
          if (props.onBlur) {
            props.onBlur(event)
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement

          if (target.value && target.value !== '') {
            if (e.key === 'Enter' || e.key === 'Tab') {
              let value: string | null = null
              props.data?.map((item) => {
                if (value === null) {
                  if (typeof item === 'string') {
                    if (item.toLowerCase().includes(target.value.toLowerCase())) {
                      value = item
                    }
                  } else {
                    if (item.label && item.label.toLowerCase().includes(target.value.toLowerCase())) {
                      value = item.value
                    }
                  }
                }
              })

              if (value && value !== '') {
                setVal(value)
                if (props.onChange) {
                  props.onChange(value)
                }
              }
            }

            if (props.onKeyDown) {
              props.onKeyDown(e)
            }
          } else {
            setVal('')
            if (props.onChange) {
              props.onChange('')
            }
          }
        }}
        onChange={(value) => {
          if (props.onChange) {
            props.onChange(value)
          }

          setVal(value)
        }}
      >
        {children}
      </Select>
      {props.clearable && (
        <Box
          style={{ position: 'absolute', right: '30px', top: props.size === 'sm' ? '14px' : '22px', cursor: 'pointer' }}
          onClick={() => {
            setVal('')
            if (props.onChange) {
              props.onChange('')
            }
          }}
        >
          <IconX size={13} color={XelaColor.Gray7}></IconX>
        </Box>
      )}
    </Box>
  )
})
export const XSelectWithRole = createPolymorphicComponent<
  'input',
  Omit<XSelectWithRoleProps, 'data'> & {
    data?: SI[] | string[]
    onInit?: (callback: (data: SI[] | string[]) => void) => void
  }
>(_XSelectWithRole)

export default XSelectWithRole
